import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { ScatterplotLayer, PolygonLayer } from "@deck.gl/layers";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import { h3ToGeo, polyfill, h3ToChildren, h3ToGeoBoundary } from "h3-js";
import PointsInHex from "../utils/FetchHexPoints";
import { Button, Row, Col, message } from "antd";
import * as turf from "@turf/turf";

export default function Map(props) {
  const ambientLight = new AmbientLight({
    color: [153, 226, 180],
    intensity: 1.0,
  });

  const pointLight1 = new PointLight({
    color: [250, 163, 7],
    intensity: 0.2,
    position: [-0.144528, 0.739968, 100],
  });

  const pointLight2 = new PointLight({
    color: [202, 240, 248],
    intensity: 0.6,
    position: [-3.807751, 54.104682, 100],
  });

  const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
  });
  const material = {
    ambient: 0.7,
    diffuse: 0,
    shininess: 10,
    specularColor: [245, 176, 65],
  };
  const [DGLayer, UpdateDGLayer] = useState([]);
  const [Ambient, UpdateAmbient] = useState(lightingEffect);
  const [LatLng, UpdateLatLng] = useState([0, 0]);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [Map, UpdateMap] = useState(false);
  const [Elevation, UpdateElevation] = useState(false);
  const [Card, UpdateCard] = useState(false);
  const [Comparison, UpdateComparison] = useState(false);
  const [Loading, UpdateLoading] = useState(1);
  const [HexInfo, UpdateHexInfo] = useState({
    id: null,
    average: null,
    count: null,
    percentage: null,
    oldVal: null,
  });
  const [FilteredHex, UpdateFilteredHex] = useState({
    count: 0,
    average: 0,
    score: 0,
  });
  const [initialViewState, setInitialViewState] = useState({
    longitude: LatLng[0],
    latitude: LatLng[1],
    zoom: 1,
    maxZoom: 20,
    pitch: 0,
    bearing: 0,
  });
  useEffect(() => {
    if (props.id) {
      setTimeout(async () => {
        let data = await PointsInHex(props.id);

        if (data.length) {
          UpdateHexagons(data);
          createhexLayer(props.id, data);
        }
      }, 3000);
    }
  }, [props.id]);
  const pointInfo = (info) => {
    message.info({
      content: `${info.operator}  ${info.tp.toFixed(2)} Mbp/s`,
      // icon: ArrowDownOutlined,
      style: {
        // marginTop: "20vh",
        position: "fixed",
        bottom: "1px",
        right: "10px",
      },
    });
  };

  function operatorColor(operator) {
    // if (operator === "DNA" || operator === "VZW") {
    return [222, 49, 99];
    // } else if (operator === "Elisa" || operator === "ATT") {
    //   return [255, 127, 80];
    // } else if (operator === "TeliaSonera" || operator === "TMO") {
    //   return [86, 171, 145];
    // } else {
    //   return [222, 49, 99];
    // }
  }
  function createhexLayer(data, sc) {
    let colors = [
      [20, 124, 2],

      [222, 30, 0],
    ];
    let hexId = data;
    let childrens = h3ToChildren(hexId, 9);
    let finalHex = [];
    let dummyData = [];
    let boundary = h3ToGeoBoundary(hexId);
    // var bbox = [0, 0, 10, 10];

    // var line = turf.lineString(boundary);
    // var bbox = turf.bbox(line);
    // var points = turf.randomPoint(Math.floor(Math.random() * 100), {
    //   bbox: bbox,
    // });

    // points.features.map((p, x) => {
    //   dummyData.push({
    //     name: "sample point",
    //     coordinates: [p.geometry.coordinates[1], p.geometry.coordinates[0]],
    //   });
    // });
    childrens.map((i, x) => {
      finalHex.push({
        _id: i,
        count: Math.random(),
        color: colors[Math.floor(Math.random() * colors.length)],
      });
    });
    UpdateLoading(false);
    // if (finalHex) {
    if (finalHex.length) {
      let showLibraries = true;
      const hexLayer = new H3HexagonLayer({
        id: props.compare ? "comaparison-layer" : "hexagon-layer",
        data: finalHex,
        pickable: true,
        // extruded: true,
        wireframe: false,
        filled: true,
        coverage: 0.979, //spaces between hexagons
        elevationScale: Elevation,
        opacity: 0.2,
        getHexagon: (d) => d._id,
        getElevation: (d) => Math.abs(d.count),
        getFillColor: (d) => [0, 0, 0],
        material,

        updateTriggers: {
          getFillColor: (d) => d.color,
          getColor: (d) => d.color,
        },
        transitions: {
          getFillColor: 100,
        },
      });

      // scater plot
      const Plotlayer = new ScatterplotLayer({
        id: "scatterplot-layer",
        // data: "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/bart-stations.json",
        data: sc,
        pickable: true,
        opacity: 1,
        stroked: false,
        filled: true,
        radiusScale: 3,
        radiusMinPixels: 1,
        radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        getPosition: (d) => d.coordinates,
        getRadius: (d) => 4,
        getFillColor: (d) => operatorColor(d.operator),
        onClick: ({ object }) => {
          pointInfo(object);
        },
      });

      let pCoords = [];
      boundary.map((i, x) => {
        pCoords.push([i[1], i[0]]);
      });
      let Data = [
        {
          // Simple polygon (array of coords)
          contour: pCoords,
          zipcode: 94107,
          population: 26599,
          area: 6.11,
        },
      ];

      const layer = new PolygonLayer({
        id: "polygon-layer",
        data: Data,
        pickable: true,
        stroked: true,
        filled: false,
        wireframe: true,
        lineWidthMinPixels: 1,
        opacity: 0.7,
        getPolygon: (d) => d.contour,

        getLineColor: [31, 31, 33],
        getLineWidth: 25,
      });

      let hexCenter = h3ToGeo(finalHex[0]._id);
      setInitialViewState({
        longitude: hexCenter[1],
        latitude: hexCenter[0],
        zoom: 14,
        maxZoom: 20,
        pitch: 0,
        bearing: 0,
        // pitch: Elevation ? 70 : 0,
        // bearing: Elevation ? 0 : 0,
      });
      // UpdateLatLng(hexCenter);
      UpdateDGLayer([hexLayer, layer, Plotlayer]);
    }
    // }
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          right: "0px",
          bottom: "0px",
          width: "400px",
          height: "100vh",
          zindex: 12,
          borderRadius: "20px",
        }}
      >
        {Loading ? (
          <>
            <div
              style={{
                position: "absolute",
                right: "0px",
                bottom: "0px",
                top: "0px",
                left: "0px",
                margin: "auto",
                height: "100px",
                zindex: 13,
                borderRadius: "20px",
              }}
            >
              <Row>
                <Col span={24} align="center">
                  <Button type="dotted" shape="round" loading>
                    Data Loading
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            {" "}
            <DeckGL
              style={{
                top: "55px",
                borderRadius: "20px",
                position: "absolute",
                // zIndex: 999,
                // right: "0px",
                // bottom: "0px",
                // height: "100px",
                // width: "300px",
              }}
              initialViewState={initialViewState}
              controller={true}
              effects={[Ambient]}
              layers={DGLayer}
              // getTooltip={({ object }) =>
              //   object && ` ${object.color} and ${object.average} `
              // }
            >
              <div
                style={{
                  position: "absolute",
                  top: "30px",
                  left: "10px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Button type="primary" shape="round">
                  {Hexagons.length} Points
                </Button>
              </div>

              <ReactMapGL
                reuseMaps
                // mapStyle={mapStyle}
                longitude={LatLng[0]}
                latitude={LatLng[1]}
                preventStyleDiffing={true}
                transitionDuration={1000}
                transitionInterpolator={new FlyToInterpolator()}
                mapStyle="mapbox://styles/mapbox/dark-v10"
                mapStyle={
                  Map
                    ? "mapbox://styles/mapbox/outdoors-v11"
                    : "mapbox://styles/mapbox/dark-v10"
                }
                //`mapbox://styles/mapbox/satellite-v9`
                // mapStyle="mapbox://styles/mapbox/outdoors-v11"
                mapStyle="mapbox://styles/mapbox/light-v10"
                // mapStyle="mapbox://styles/mapbox/dark-v10"
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxApiAccessToken="pk.eyJ1Ijoic2FsbWFuanZkIiwiYSI6ImNrc3JjaXFlZzBsanUybnRmZHFhNGpmdmYifQ.cYc--QH_zO99dPIsBMSAcQ"
              />
            </DeckGL>
          </>
        )}
      </div>
    </>
  );
}
