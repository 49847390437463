import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import BinCard from "./BinInfoCard";
import { Select } from "antd";
import { h3ToGeo, polyfill, h3ToChildren } from "h3-js";

import {
  Button,
  Radio,
  Switch,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Statistic,
} from "antd";
import * as d3 from "d3";
import { BulbOutlined, SignalFilled, LikeOutlined } from "@ant-design/icons";
const { Option } = Select;

export default function Map(props) {
  const ambientLight = new AmbientLight({
    color: [153, 226, 180],
    intensity: 2,
  });

  const pointLight1 = new PointLight({
    color: [250, 163, 7],
    intensity: 0.44,
    position: [-0.144528, 0.739968, 100],
  });

  const pointLight2 = new PointLight({
    color: [202, 240, 248],
    intensity: 0.6,
    position: [-3.807751, 54.104682, 100],
  });

  const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
  });
  const material = {
    ambient: 0.7,
    diffuse: 0,
    shininess: 10,
    specularColor: [245, 176, 65],
  };
  const [DGLayer, UpdateDGLayer] = useState([]);
  const [Ambient, UpdateAmbient] = useState(lightingEffect);
  const [LatLng, UpdateLatLng] = useState([0, 0]);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [Map, UpdateMap] = useState(false);
  const [Elevation, UpdateElevation] = useState(false);
  const [Card, UpdateCard] = useState(false);
  const [Comparison, UpdateComparison] = useState(false);
  const columns = [
    {
      title: "Hex",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Samples",
      dataIndex: "age",
      key: "age",
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "green" : "green";
            if (tag === "failed") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <a>Details</a>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "88283082b9fffff",
      age: Math.floor(Math.random() * 100),
      address: "New York No. 1 Lake Park",
      tags: ["failed"],
    },
    {
      key: "2",
      name: "88283082b9fffff",
      age: Math.floor(Math.random() * 100),
      address: "London No. 1 Lake Park",
      tags: ["passed"],
    },
    {
      key: "3",
      name: "88283082b9fffff",
      age: Math.floor(Math.random() * 100),
      address: "Sidney No. 1 Lake Park",
      tags: ["passed"],
    },
    {
      key: "4",
      name: "88283082b9fffff",
      age: Math.floor(Math.random() * 100),
      address: "Sidney No. 1 Lake Park",
      tags: ["passed"],
    },
    {
      key: "5",
      name: "88283082b9fffff",
      age: Math.floor(Math.random() * 100),
      address: "Sidney No. 1 Lake Park",
      tags: ["failed"],
    },
    {
      key: "6",
      name: "88283082b9fffff",
      age: Math.floor(Math.random() * 100),
      address: "Sidney No. 1 Lake Park",
      tags: ["failed"],
    },
    // {
    //   key: "7",
    //   name: "88283082b9fffff",
    //   age: Math.floor(Math.random() * 100 + 100000),
    //   address: "Sidney No. 1 Lake Park",
    //   tags: ["passed"],
    // },
  ];

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0px",
          right: "0px",
          backgroundColor: "white",
          width: "400px",
          height: "50vh",

          zindex: 10000,
          // borderRadius: "20px",
        }}
      >
        {" "}
        <br></br>
        <Row gutter={16} spaces={4} style={{ paddingLeft: "10px" }}>
          <Col span={12}>
            {/* <h5> Passed</h5> */}
            {props.color ? (
              props.color.toString() === "222,30,0" ? (
                <>
                  <Button type="dashed" color="red" success>
                    {" "}
                    Failed
                  </Button>
                  <Space size="middle"></Space>
                  <Button danger type="primary">
                    Challenge It
                  </Button>
                </>
              ) : (
                <>
                  <Button type="dashed" color="success">
                    {" "}
                    Passed
                  </Button>
                  <Space size="middle"></Space>
                  <Button disabled>Challenge It</Button>
                </>
              )
            ) : (
              <Tag color="red"> Failed</Tag>
            )}
            {/* <Tag color="success"> Passed</Tag> */}
            {/* <Statistic
              title={<Tag color="success">Passed</Tag>}
              value="3 out of 7"
              prefix={<LikeOutlined />}
            /> */}
          </Col>
          <Col span={12}>
            {/* <Tag color="gold">Failed/Passed</Tag> */}
            {/* <h5> Status</h5> */}
            {/* <Statistic
              // title={<Tag color="gold">Failed/Passed</Tag>}
              value={3}
              suffix="/ 7"
            /> */}
            {/* <Tag color="red"> False</Tag> */}
          </Col>
        </Row>{" "}
        <br></br>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={true}
        />
        ,
      </div>
    </>
  );
}
