import React, { useState, useEffect } from "react";

import CityList from "./CityList";
import OperatorList from "./OperatorList";
import MeasureList from "./MeasurementList";
import GoogleSearch from "./GoogleSearch";
import {
  Divider,
  Button,
  DatePicker,
  Tooltip,
  Switch,
  Collapse,
  Row,
  Col,
  Card,
  Statistic,
} from "antd";
import {
  SearchOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CaretRightOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  ExclamationOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import moment from "moment";
import "./../App.css";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

function SideBarRight(props) {
  const [Comparison, UpdateComparison] = useState(false);
  function getDates(e, dateString) {
    let firstDate = moment.utc(e[0]._d);
    let secondtDate = moment.utc(e[1]._d);

    props.updatefilters({
      city: props.filters.city,
      operator: props.filters.operator,
      measurement: props.filters.measurement,
      date: {
        first: firstDate,
        second: secondtDate,
      },
      // call: true,
    });
  }
  function FetchResults() {
    // let filters = { ...props.filters };
    // filters.call = true;
    // props.updatefilters(filters);
    props.call(true);
  }

  function Reset() {
    props.updatefilters({
      city: null,
      operator: null,
      measurement: null,
      date: {
        first: null,
        second: null,
      },
      call: false,
    });
    let dates = { ...props.comparison };
    dates.first = null;
    dates.second = null;
    props.updatecomparson(dates);
    props.updatereset(true);
  }

  function CompareDates(e) {
    let dates = { ...props.comparison };
    dates.first = e[0]._d;
    dates.second = e[1]._d;
    props.updatecomparson(dates);
  }

  useEffect(() => {
    if (!Comparison) {
      let dates = { ...props.comparison };
      dates.first = null;
      dates.second = null;
      props.updatecomparson(dates);
      props.updatecompare(false);
    }
  }, [Comparison]);
  const dateFormat = "YYYY-MM-DD";
  return (
    <>
      <div className="side-bar-right">
        <div style={{ textAlign: "center" }}>
          {" "}
          <br></br>
          <img src="mesh2.png" style={{ height: "50px" }}></img>
          <br></br>
          {/* <b style={{ fontSize: "1.4em" }}>QoS</b> */}
          <br></br>
        </div>
        <>
          {/* <Divider orientation="left">Search</Divider> */}
          <>
            <GoogleSearch
              updatefilters={props.updatefilters}
              updatepolygon={props.updatepolygon}
              filters={props.filters}
              reset={props.reset}
            />
          </>
        </>

        <>
          <br></br>
          {/* <Tooltip title="search">
            <Button
              style={{ float: "right" }}
              type="primary"
              icon={<SearchOutlined />}
              loading={props.loading}
              onClick={() => {
                FetchResults();
              }}
            >
              Search
            </Button>
          </Tooltip> */}
          {props.polygon.length ? (
            <>
              <Tooltip title="clear search">
                <Button
                  style={{
                    float: "right",
                    marginRight: "10px",
                  }}
                  type="dashed"
                  onClick={() => {
                    Reset();
                  }}
                >
                  Clear Search
                </Button>
              </Tooltip>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <>
                <Row>
                  <Col span={6}>
                    <CaretRightOutlined />
                  </Col>
                  <Col span={10}>Total Bins</Col>
                  <Col span={8}>994</Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col span={6}>
                    <CaretUpOutlined style={{ color: "#79B473" }} />
                  </Col>
                  <Col span={10}>Ok Bins</Col>
                  <Col span={8}>944</Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  {/* <Col span={24}> */}
                  {/* <Card>
                      <Statistic
                        title="Not Ok"
                        value={46}
                        precision={0}
                        // valueStyle={{ color: "#f63000" }}
                        // prefix={<ExclamationOutlined />}
                        suffix="bins"
                      />
                    </Card> */}

                  <Col span={6}>
                    <CaretDownOutlined style={{ color: "#E21653" }} />
                  </Col>
                  <Col span={10}>Not Ok Bins</Col>
                  <Col span={8}>50</Col>
                  {/* </Col> */}
                  {/* <Col span={24}>
                    <br></br>
                  </Col>
                  <Col span={24}>
                    <Card>
                      <Statistic
                        title="Old"
                        value={91}
                        precision={0}
                        valueStyle={{ color: "#AA7945" }}
                        prefix={<HistoryOutlined />}
                        suffix="bins"
                      />
                    </Card>
                  </Col> */}
                </Row>
              </>
            </>
          ) : (
            <></>
          )}
        </>
      </div>
    </>
  );
}
export default SideBarRight;
