import React from "react";
import "./../App.css";
import { Select } from "antd";
const { Option } = Select;
function CityList(props) {
  return (
    <>
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Select a city"
        optionFilterProp="children"
        onChange={(e) => {
          props.selectcity(e);
        }}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        // filterOption={(input, option) =>
        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
      >
        {props.cities.map((data) => {
          return <Option value={data}>{data}</Option>;
        })}
      </Select>
    </>
  );
}
export default CityList;
