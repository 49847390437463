import React, { useState, useEffect } from "react";

import RightSidebar from "./components/InfoBarRight";
import Map from "./components/Map";
import "./App.css";
import "antd/dist/antd.css";
import { Row, Col, Skeleton, notification } from "antd";
import moment from "moment";
import axios from "axios";
import SanFrancisco from "./components/data.json";
import _ from "lodash";
import { polyfill } from "h3-js";
import * as turf from "@turf/turf";

function App() {
  const URL = "https://api.revolutionapp.io/api/qos/";
  // const URL = "http://localhost:3004/";

  const [Cities, UpdateCities] = useState([]);
  const [SelectedCity, UpdateSelectedCity] = useState(null);
  const [SelectedOperator, UpdateSelectedOperator] = useState(null);
  const [SelectedMeasure, UpdateSelectedMeasure] = useState(null);

  const [ComparisonDate, UpdateComparisonDate] = useState({
    first: null,
    second: null,
  });
  const [Operators, UpdateOperators] = useState([]);
  const [Compare, UpdateCompare] = useState(false);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [SelectedHexagons, UpdateSelectedHexagons] = useState([]);
  const [Polygon, UpdatePolygon] = useState([]);
  const [Reset, UpdateReset] = useState(false);

  const [CallApi, UpdateCallApi] = useState(false);
  const [HexComaparison, UpdateHexComparison] = useState({
    IncreasedBins: [],
    DecreasedBins: [],
    NewBins: [],
    TotalBins: 0,
  });
  const [Loading, UpdateLoading] = useState(false);
  const [Filters, UpdateFilters] = useState({
    city: null,
    operator: null,
    measurement: "RSRP",
    date: {
      first: moment(new Date(), "YYYY-MM-DD"),
      second: moment(new Date(), "YYYY-MM-DD").subtract(1, "month"),
    },
    call: false,
    coordinates: [],
  });

  const openNotificationWithIcon = (title, message, type) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  useEffect(() => {
    if (Reset) {
      UpdatePolygon([]);
      UpdateOperators([]);
      UpdateHexagons([]);
      UpdateHexComparison({
        IncreasedBins: [],
        DecreasedBins: [],
        NewBins: [],
        TotalBins: 0,
      });
      UpdateReset(false);
    }
  }, [Reset]);

  // useEffect(() => {
  //   console.log("Po;lygon is", Polygon);
  // }, [Polygon]);
  useEffect(() => {
    (async () => {
      if (Filters.call) {
        // call api for hexagons
        UpdateLoading(true);

        if (!Filters.date) {
          openNotificationWithIcon(
            "Select Date",
            "Please select date range to proceed",
            "error"
          );
        } else {
          //make hex call for API
          Filters.coordinates = Polygon;
          let hexData = await axios.post(URL + "hexagons", Filters);
          if (hexData.data.HexComparison) {
            //   // let binComp = JSON.parse(hexData.data.HexComparison);

            UpdateHexComparison({
              Combined: hexData.data.HexDataCombined,
              IncreasedBins: hexData.data.HexComparison["IncreasedBins"],
              DecreasedBins: hexData.data.HexComparison["DecreasedBins"],
              NewBins: hexData.data.HexComparison["NewBins"],
              UnChangedBins: hexData.data.HexComparison["UnChangedBins"],
              TotalBins: hexData.data.HexDataCombined.length,
              TotalAverage: hexData.data.HexComparison["TotalAverage"],
            });
          }

          UpdateHexagons(hexData.data.HexData);
          UpdateLoading(false);
        }
      }
    })();
  }, [Filters]);

  // }, [SelectedMeasure]);

  useEffect(async () => {
    if (Polygon.length) {
      let colors = [
        [121, 180, 115], // green
        [226, 22, 83], // red
        [170, 121, 69], // blue
      ];

      try {
        let Chicago_1 = [
          [-87.74211909083536, 41.68403747387462],
          [-87.61731873804052, 41.64339512516345],
          [-87.54140507949431, 41.64384918749587],
          [-87.53808965289353, 41.66376061285585],
          [-87.60489477449313, 41.6641153515983],
          [-87.60631343207791, 41.70337857494329],
          [-87.53070527036522, 41.70247236986629],
          [-87.52880192284181, 41.73900622932248],
          [-87.68335334498889, 41.73613778270107],
          [-87.74211909083536, 41.68403747387462],
          ,
        ];

        let Chicago_3 = [
          [-87.78667560406046, 41.96859385537122],
          [-87.78647012765303, 41.73987579714702],
          [-87.53485624192237, 41.74569299412456],
          [-87.64630448540477, 41.96858094982895],
          [-87.78667560406046, 41.96859385537122],
          ,
        ];

        let Chicago_2 = [
          [-87.94154022787188, 42.00487858975347],
          [-87.93717743555408, 41.91890034108482],
          [-87.81277135463566, 41.90911279322342],
          [-87.81748412072467, 41.98555535817987],
          [-87.67034110676549, 41.98454357021318],
          [-87.66134345461879, 42.02472632748103],
          [-87.94154022787188, 42.00487858975347],
        ];

        let ch_1 = [];
        let ch_2 = [];
        let ch_3 = [];

        function range(start, end) {
          return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
        }

        Chicago_1.map((i, x) => {
          ch_1.push([i[1], i[0]]);
        });
        Chicago_2.map((i, x) => {
          ch_2.push([i[1], i[0]]);
        });
        Chicago_3.map((i, x) => {
          ch_3.push([i[1], i[0]]);
        });
        // const hexagons_ch1 = await polyfill(ch_1, 8);
        // const hexagons_ch2 = await polyfill(ch_2, 8);
        // const hexagons_ch3 = await polyfill(ch_3, 8);
        // const hexagons = polyfill(manchester, 8);
        let hexagons = [];
        // hexagons.push(...hexagons_ch1);
        // hexagons.push(...hexagons_ch2);
        // hexagons.push(...hexagons_ch3);

        /**
         * @hex types
         */
        hexagons = SanFrancisco;
        console.log("Total Hex Are", hexagons.length);
        let ok = new Array(Math.round((hexagons.length * 95) / 100)); // 70%
        let notOk = new Array(Math.round((hexagons.length * 5) / 100)); // 10%
        let old = new Array(Math.round((hexagons.length * 0) / 100)); // 20%

        var allIndecies = range(0, hexagons.length - 1);

        const chooseRandom = (arr, num = 1) => {
          const res = [];
          for (let i = 0; i < num; ) {
            const random = Math.floor(Math.random() * arr.length);
            if (res.indexOf(arr[random]) !== -1) {
              continue;
            }
            res.push(arr[random]);
            i++;
          }
          return res;
        };

        ok = chooseRandom(allIndecies, ok.length);
        _.pullAll(allIndecies, ok);
        // not ok
        notOk = chooseRandom(allIndecies, notOk.length);
        _.pullAll(allIndecies, notOk);
        // old
        old = chooseRandom(allIndecies, old.length);
        _.pullAll(allIndecies, old);
        console.log(ok.length, ">>", notOk.length, ">>", old.length, ">>");
        let finalHex = [];

        hexagons.map((i, x) => {
          let newColor;
          let isOK = ok.indexOf(x);
          let isNotOK = notOk.indexOf(x);
          let isOld = old.indexOf(x);

          if (isOK > -1) {
            newColor = colors[0];
          }
          if (isNotOK > -1) {
            newColor = colors[1];
          }
          if (isOld > -1) {
            newColor = colors[2];
          }

          finalHex.push({
            _id: i._id,
            count: Math.random(),
            color: newColor,
          });
        });
        // console.log(finalHex);
        UpdateHexagons(finalHex);
      } catch (err) {
        console.log("Oops", err);
      }
    }
  }, [Polygon]);

  useEffect(() => {
    if (ComparisonDate.first) {
      let newFilters = { ...Filters };
      newFilters.comparison = ComparisonDate;
      newFilters.compare = true;

      UpdateFilters(newFilters);
    }
  }, [ComparisonDate]);

  useEffect(async () => {
    if (CallApi) {
      if (!Polygon.length) {
        openNotificationWithIcon(
          "No Operator!",
          "Please select a location!",
          "error"
        );
      } else if (!Filters.operator) {
        openNotificationWithIcon(
          "No Operator!",
          "Please select an operator!",
          "error"
        );
      } else if (!Filters.date) {
        openNotificationWithIcon(
          "Select Date",
          "Please select date range to proceed",
          "info"
        );
      } else {
        //make hex call for API
        Filters.coordinates = Polygon;
        UpdateLoading(true);
        let hexData = await axios.post(URL + "hexagons", Filters);
        if (hexData.data.HexComparison) {
          UpdateHexComparison({
            Combined: hexData.data.HexDataCombined,
            IncreasedBins: hexData.data.HexComparison["IncreasedBins"],
            DecreasedBins: hexData.data.HexComparison["DecreasedBins"],
            NewBins: hexData.data.HexComparison["NewBins"],
            UnChangedBins: hexData.data.HexComparison["UnChangedBins"],
            TotalBins: hexData.data.HexDataCombined.length,
            TotalAverage: hexData.data.HexComparison["TotalAverage"],
          });
        }

        if (hexData.data.HexData.length == 0) {
          openNotificationWithIcon(
            "No Data",
            "Please search with other options!",
            "info"
          );
        }

        UpdateHexagons(hexData.data.HexData);
        UpdateLoading(false);
      }
      UpdateCallApi(false);
    }
  }, [CallApi]);

  return (
    <>
      {/* {Cities.length === 0 ? (
        <Skeleton active paragraph={{ rows: 10 }} title={true} />
      ) : ( */}
      <>
        {" "}
        <Row>
          <Col span={4}>
            <RightSidebar
              filters={Filters}
              updatefilters={UpdateFilters}
              cities={Cities}
              updatepolygon={UpdatePolygon}
              polygon={Polygon}
              operators={Operators}
              selectmeasurement={UpdateSelectedMeasure}
              selectoperator={UpdateSelectedOperator}
              selectcity={UpdateSelectedCity}
              loading={Loading}
              hexagons={Hexagons.length}
              comparison={ComparisonDate}
              updatecomparson={UpdateComparisonDate}
              call={UpdateCallApi}
              updatecompare={UpdateCompare}
              compare={Compare}
              updatereset={UpdateReset}
              reset={Reset}
            />
          </Col>

          <Col span={Hexagons.length ? 18 : 20}>
            <Map
              hexagons={Hexagons}
              polygon={Polygon}
              selectedhexagons={SelectedHexagons}
              updatehex={UpdateSelectedHexagons}
              city={SelectedCity}
              comparison={HexComaparison}
              filters={Filters}
              compare={Compare}
              reset={Reset}
            />
          </Col>
        </Row>
      </>
    </>
  );
}

export default App;
