import axios from "axios";
/**
 * this functions fetch all points inside hex from bigquery
 */

export default async function FetchHexPoints(hex) {
  let data = await axios.post(
    // "https://api.revolutionapp.io/api/qos/points/hex",
    "https://api.revolutionapp.io/api/qos/points/hex",
    // "http://localhost:3004/points/hex",
    {
      hex,
    }
  );

  if (data.data.length) {
    return data.data;
  } else {
    return [];
  }
}
