import React, { useState, useEffect } from "react";
import { Button, Row, Col, Divider, Select, message } from "antd";
const { Option } = Select;
export default function ChallengeProcess(props) {
  const info = () => {
    message.info("Challenge submitted for this hex.");
  };
  const [Loading, Updateloading] = useState(false);
  function Submit() {
    Updateloading(true);
    setTimeout(() => {
      Updateloading(false);
      info();
      props.updatecard(!props.card);
    }, 3000);
  }
  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          top: "0px",
          backgroundColor: "white",
          width: "100%",
          height: "100vh",
          padding: "5%",

          zindex: 10000,
          // borderRadius: "20px",
        }}
      >
        {" "}
        <br></br>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={12}>Test Protocol</Col>
          <Col span={12}>
            <Select
              defaultValue="lucy"
              style={{ width: 120 }}
              //   onChange={handleChange}
            >
              <Option value="jack">FCC</Option>
              <Option value="lucy">Other</Option>
              <Option value="disabled">Other</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={12}>Script</Col>
          <Col span={12}>
            <Select
              defaultValue="lucy"
              style={{ width: 120 }}
              //   onChange={handleChange}
            >
              <Option value="jack">DL</Option>
              <Option value="lucy">UL</Option>
              <Option value="disabled">Call</Option>
              <Option value="Yiminghe">SMS</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={12}>Device Mode</Col>
          <Col span={12}>
            <Select
              defaultValue="lucy"
              style={{ width: 120 }}
              //   onChange={handleChange}
            >
              <Option value="jack">Dedicated</Option>
              <Option value="lucy">User Device</Option>
              <Option value="disabled">Both</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>

          <Col span={18} align="right">
            <Button type="dotted" onClick={() => props.updatecard(!props.card)}>
              Cancel
            </Button>
          </Col>
          <Col span={6} align="right">
            <Button type="primary" loading={Loading} onClick={() => Submit()}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
